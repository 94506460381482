import {PageName} from "../../config/general";

export const Header = () => {
    return <header className={"header-container"}>
        <div className={"logo-container"}>
            <a href="/" className={"logo-link"}>
                <img className={"logo-img"} src={window.location.origin + "/images/logos/Thai Stop-04.png"}
                     alt={PageName + " logo"}/>
            </a>
        </div>
        <nav className={"nav-container"}>
            <a href="/">Home</a>
            <a href="/menu">Menu</a>
        </nav>
    </header>
}