import { FaPhone, FaMap } from 'react-icons/fa';
import {
    Address,
    AddressLink,
    OpenDaysFirstRow,
    OpenDaysSecondRow,
    OpenTimesFirstRow,
    OpenTimesSecondRow,
    Phone,
    PhoneDisplay, Slogan
} from "../../config/general";


export const Home = () => {
    return <div className={"home-page-container"}>
        <div className={"home-page-content-container"}>
            <h1 className={"slogan"}>{Slogan}</h1>

            <div className={"open-info-container"}>
                <div className={"open-info-row"}>
                    <div className={"open-info-days"}>
                        <FaPhone style={{
                            fontSize: "2rem"
                        }}/>
                    </div>
                    <div className={"open-info-times"}>
                        <span className={"phonenumber"}>
                            <a className={"phonelink"} href={`tel:${Phone}`}>{PhoneDisplay}</a>
                        </span>
                    </div>
                </div>
            </div>
            <div className={"open-info-container"}>
                <div className={"open-info-row"}>
                    <div className={"open-info-days"}>
                        <FaMap style={{
                            fontSize: "2rem"
                        }}/>
                    </div>
                    <div className={"open-info-times"}>
                        <span className={"phonenumber"}>
                            <a className={"phonelink"} href={`${AddressLink}`}>{Address}</a>
                        </span>
                    </div>
                </div>
            </div>

            <div>
                <h2>We are open:</h2>
            </div>
            <div className={"open-info-container"}>
                <div className={"open-info-row"}>
                    <div className={"open-info-days"}>
                        {OpenDaysFirstRow}
                    </div>
                    <div className={"open-info-times time"}>
                        {OpenTimesFirstRow}
                    </div>
                </div>
                <div className={"open-info-row"}>
                    <div className={"open-info-days"}>
                        {OpenDaysSecondRow}
                    </div>
                    <div className={"open-info-times time"}>
                        {OpenTimesSecondRow}
                    </div>
                </div>
            </div>

        </div>
    </div>
}