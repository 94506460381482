export const menuItems = [
    {
        category: "Eelroad/Appetizers",
        items: [
            {
                nr: 1,
                nameET: "Krõbedad kanavardad",
                nameEN: "Crispy Chicken Sticks",
                peppers: 0,
                price: 9
            },
            {
                nr: 2,
                nameET: "Krõbedad krevetivardad",
                nameEN: "Crispy Shrimp Sticks",
                peppers: 0,
                price: 9.5
            },
            {
                nr: 3,
                nameET: "Kevadrullid kanaga",
                nameEN: "Springrolls With Chicken",
                peppers: 0,
                price: 9
            },
            {
                nr: 4,
                nameET: "Kevadrullid Krevettidega",
                nameEN: "Springrolls With Shrimps",
                peppers: 0,
                price: 9.5
            },
            {
                nr: 5,
                nameET: "Kanatiivad",
                nameEN: "Chicken Wings",
                peppers: 1,
                price: 9
            },
            {
                nr: 6,
                nameET: "Krevetikoogid",
                nameEN: "Shrimp Cakes",
                peppers: 0,
                price: 10
            }
        ]
    },
    {
        category: "Supid/Soups",
        items: [
            {
                nr: 7,
                nameET: "Tom Kha Kanaga",
                nameEN: "Tom Kha With Chicken",
                peppers: 1,
                price: 9.5
            },
            {
                nr: 8,
                nameET: "Tom Kha Krevettidega",
                nameEN: "Tom Kha With Shrimps",
                peppers: 1,
                price: 10.5
            },
            {
                nr: 9,
                nameET: "Tom Yum Kanaga",
                nameEN: "Tom Yum With Chicken",
                peppers: 2,
                price: 9.5
            },
            {
                nr: 10,
                nameET: "Tom Yum Krevettidega",
                nameEN: "Tom Yum With Shrimps",
                peppers: 2,
                price: 10.5
            },
            {
                nr: 11,
                nameET: "Tom Chuet Sealiha Ja Köögiviljadega",
                nameEN: "Tom Chuet With Pork And Vegetables",
                peppers: 0,
                price: 9.5
            },
            {
                nr: 12,
                nameET: "Thai Stop Nuudlisupp Kanaga",
                nameEN: "Thai Stop Noodle Soup With Chicken",
                peppers: 1,
                price: 9.5
            },
            {
                nr: 13,
                nameET: "Thai Stop Nuudlisupp Krevettidega",
                nameEN: "Thai Stop Noodle Soup With Shrimps",
                peppers: 1,
                price: 10.5
            },
            {
                nr: 14,
                nameET: "Tom Yum Nuudlisupp Kanaga",
                nameEN: "Tom Yum Noodle Soup With Chicken",
                peppers: 2,
                price: 9.5
            },
            {
                nr: 15,
                nameET: "Tom Yum Nuudlisupp Krevettidega",
                nameEN: "Tom Yum Noodle Soup With Shrimps",
                peppers: 2,
                price: 10.5
            }
        ]
    },
    {
        category: "Salatid/Salads",
        items: [
            {
                nr: 16,
                nameET: "Laab Moo",
                nameEN: "Laab Moo",
                peppers: 2,
                price: 10
            },
            {
                nr: 17,
                nameET: "Laab Gai",
                nameEN: "Laab Gai",
                peppers: 2,
                price: 10
            },
            {
                nr: 18,
                nameET: "Nam Tok",
                nameEN: "Nam Tok",
                peppers: 2,
                price: 10
            },
            {
                nr: 19,
                nameET: "Klaasnuudlisalat",
                nameEN: "Glass Noodle Salad",
                peppers: 2,
                price: 10
            }
        ]
    },
    {
        category: "Nuudlid/Noodles",
        items: [
            {
                nr: 20,
                nameET: "Pad Thai Kanaga",
                nameEN: "Pad Thai With Chicken",
                peppers: 0,
                price: 11
            },
            {
                nr: 21,
                nameET: "Pad Thai Krevettidega",
                nameEN: "Pad Thai With Shrimps",
                peppers: 0,
                price: 12
            },
            {
                nr: 22,
                nameET: "Pad Thai Tom Yum Kanaga",
                nameEN: "Pad Thai Tom Yum With Chicken",
                peppers: 3,
                price: 12
            },
            {
                nr: 23,
                nameET: "Pad Thai Tom Yum Krevettidega",
                nameEN: "Pad Thai Tom Yum With Shrimps",
                peppers: 3,
                price: 13
            },
            {
                nr: 24,
                nameET: "Hong Kongi Nuudlid Kanaga",
                nameEN: "Hong Kong Noodles With Chicken",
                peppers: 0,
                price: 11
            },
            {
                nr: 25,
                nameET: "Hong Kongi Nuudlid Krevettidega",
                nameEN: "Hong Kong Noodles With Shrimps",
                peppers: 0,
                price: 12
            },
            {
                nr: 26,
                nameET: "Pad Kee Mao Kanaga",
                nameEN: "Pad Kee Mao With Chicken",
                peppers: 2,
                price: 11
            },
            {
                nr: 27,
                nameET: "Pad Kee Mao Krevettidega",
                nameEN: "Pad Kee Mao With Shrimps",
                peppers: 2,
                price: 12
            },
            {
                nr: 28,
                nameET: "Thai Stop Nuudlid Kanaga",
                nameEN: "Thai Stop Noodles With Chicken",
                peppers: 2,
                price: 11
            },
            {
                nr: 29,
                nameET: "Thai Stop Nuudlid Krevettidega",
                nameEN: "Thai Stop Noodles With Shrimps",
                peppers: 2,
                price: 12
            }
        ]
    },
    {
        category: "Praetud riis/Fried rice",
        items: [
            {
                nr: 30,
                nameET: "Praetud Riis Kanaga",
                nameEN: "Fried Rice With Chicken",
                peppers: 0,
                price: 10
            },
            {
                nr: 31,
                nameET: "Praetud Riis Krevettidega",
                nameEN: "Fried Rice With Shrimps",
                peppers: 0,
                price: 11
            }
        ]
    },
    {
        category: "Wok-Riis/Wok-Rice",
        items: [
            {
                nr: 32,
                nameET: "Krõbedad kanavardad",
                nameEN: "Crispy Chicken Sticks",
                peppers: 1,
                price: 11
            },
            {
                nr: 33,
                nameET: "Krõbedad krevetivardad",
                nameEN: "Crispy Shrimp Sticks",
                peppers: 0,
                price: 11
            },
            {
                nr: 34,
                nameET: "Krõbedad krevetivardad",
                nameEN: "Crispy Shrimp Sticks",
                peppers: 0,
                price: 11
            },
            {
                nr: 35,
                nameET: "Krõbedad krevetivardad",
                nameEN: "Crispy Shrimp Sticks",
                peppers: 2,
                price: 11
            },
            {
                nr: 36,
                nameET: "Krõbedad krevetivardad",
                nameEN: "Crispy Shrimp Sticks",
                peppers: 2,
                price: 12
            },
            {
                nr: 37,
                nameET: "Krõbedad krevetivardad",
                nameEN: "Crispy Shrimp Sticks",
                peppers: 2,
                price: 11
            },
            {
                nr: 38,
                nameET: "Krõbedad krevetivardad",
                nameEN: "Crispy Shrimp Sticks",
                peppers: 2,
                price: 12
            }
        ]
    },
    {
        category: "Karri/Curry",
        items: [
            {
                nr: 39,
                nameET: "Roheline Karri Kanaga",
                nameEN: "Green Curry With Chicken",
                peppers: 3,
                price: 11
            },
            {
                nr: 40,
                nameET: "Roheline Karri Krevettidega",
                nameEN: "Green Curry With Shrimps",
                peppers: 3,
                price: 12
            },
            {
                nr: 41,
                nameET: "Punane Karri Kanaga",
                nameEN: "Red Curry With Chicken",
                peppers: 2,
                price: 11
            },
            {
                nr: 42,
                nameET: "Punane Karri Krevettidega",
                nameEN: "Red Curry With Shrimps",
                peppers: 2,
                price: 12
            },
            {
                nr: 43,
                nameET: "Panang Karri Kanaga",
                nameEN: "Panang Curry With Chicken",
                peppers: 2,
                price: 12
            },
            {
                nr: 44,
                nameET: "Panang Karri Krevettidega",
                nameEN: "Panang Curry With Shrimps",
                peppers: 2,
                price: 13
            },
            {
                nr: 45,
                nameET: "Panang Karri Kalaga",
                nameEN: "Panang Curry With Fish",
                peppers: 2,
                price: 13
            },
            {
                nr: 46,
                nameET: "Massaman Karri Kanaga",
                nameEN: "Massaman Curry With Chicken",
                peppers: 2,
                price: 11
            },
            {
                nr: 47,
                nameET: "Massaman Karri Krevettidega",
                nameEN: "Massaman Curry With Shrimps",
                peppers: 2,
                price: 12
            }
        ]
    },
    {
        category: "Lastele/For Children",
        items: [
            {
                nr: 48,
                nameET: "Lastenuudlid",
                nameEN: "Children's Noodles",
                peppers: 0,
                price: 7
            },
            {
                nr: 49,
                nameET: "Riis Kanaga",
                nameEN: "Rice With Chicken",
                peppers: 0,
                price: 7
            },
            {
                nr: 50,
                nameET: "Praetud Riis Munaga",
                nameEN: "Fried Rice With Egg",
                peppers: 0,
                price: 7
            },
            {
                nr: 51,
                nameET: "Laste Nuudlisupp",
                nameEN: "Children's Noodle Soup",
                peppers: 0,
                price: 6
            }
        ]
    },
    {
        category: "Magustoidud/Desserts",
        items: [
            {
                nr: 52,
                nameET: "Kleepuv Riis Maasikatega",
                nameEN: "Sticky Rice With Strawberries",
                peppers: 0,
                price: 8
            },
            {
                nr: 53,
                nameET: "Kleepuv Riis Mangoga (saadaval hooajaliselt)",
                nameEN: "Sticky Rice With Mango (avalable seasonaly)",
                peppers: 0,
                price: 9
            },
            {
                nr: 54,
                nameET: "Jäätis",
                nameEN: "Ice Cream",
                peppers: 0,
                price: 6
            },
            {
                nr: 55,
                nameET: "Küpsetatud Banaan Jäätisega",
                nameEN: "Baked Banana With Ice Cream",
                peppers: 0,
                price: 7
            }
        ]
    },
    {
        category: "Vegan",
        items: [
            {
                nr: 56,
                nameET: "Tofu Vardad",
                nameEN: "Tofu Sticks",
                peppers: 0,
                price: 8
            },
            {
                nr: 57,
                nameET: "Vegan Kevadrullid",
                nameEN: "Vegan Springrolls",
                peppers: 0,
                price: 8
            },
            {
                nr: 58,
                nameET: "Wokitud Köögiviljad Tofu Ja Riisiga",
                nameEN: "Wok Vegetables With Tofu And Rice",
                peppers: 0,
                price: 10
            },
            {
                nr: 59,
                nameET: "Wokitud Tofu Küüslaugukastmes",
                nameEN: "Wok Tofu In Garlic Sauce",
                peppers: 0,
                price: 10
            },
            {
                nr: 60,
                nameET: "Laab Tofu",
                nameEN: "Laab Tofu",
                peppers: 2,
                price: 10
            },
            {
                nr: 61,
                nameET: "Wokitud Tofu Tamarindi Kastmes",
                nameEN: "Wok Tofu In Tamarind Sauce",
                peppers: 1,
                price: 10
            },
            {
                nr: 62,
                nameET: "Praetud Riis Tofuga",
                nameEN: "Fried Rice With Tofu",
                peppers: 0,
                price: 10
            },
            {
                nr: 63,
                nameET: "Pad Thai Tofuga",
                nameEN: "Pad Thai With Tofu",
                peppers: 0,
                price: 10
            }
        ]
    },
    {
        category: "Joogid/Drinks",
        items: [
            {
                nameET: "Coca-Cola 50cl",
                price: 3
            },
            {
                nameET: "Coca-Cola Zero 50cl",
                price: 3
            },
            {
                nameET: "Coca-Cola 33cl",
                price: 2.6
            },
            {
                nameET: "Coca-Cola Zero 33cl",
                price: 2.6
            },
            {
                nameET: "Fanta 33cl",
                price: 2.6
            },
            {
                nameET: "Sprite 33cl",
                price: 2.6
            },
            {
                nameET: "Fuzetea 50cl",
                price: 3
            },
            {
                nameET: "Vesi Neptunas gaasita 50cl",
                nameEN: "Water Neptunas Still 50cl",
                price: 2.2
            },
            {
                nameET: "Vesi Neptunas gasseeritud 50cl",
                nameEN: "Water Neptunas Sparkling 50cl",
                price: 2.2
            },
            {
                nameET: "Mahl 25cl",
                nameEN: "Juice 25cl",
                price: 2
            },
            {
                nameET: "Laste Kõrrejook 20cl",
                nameEN: "Kid's Strawdrink 20cl",
                price: 2
            }
        ]
    },
    {
        category: "Kuumad joogid/Hot Drinks",
        items: [
            {
                nameET: "Kohv",
                nameEN: "Coffee",
                price: 2.5
            },
            {
                nameET: "Espresso",
                price: 2.5
            },
            {
                nameET: "Cappuccino",
                price: 2.5
            },
            {
                nameET: "Latte macchiato",
                price: 2.5
            },
            {
                nameET: "Ristretto",
                price: 2.5
            },
            {
                nameET: "Cafe au lait",
                price: 2.5
            },
            {
                nameET: "Tee",
                price: 2
            }
        ]
    },
    {
        category: "Alkohoolsed joogid/Drinks with alcohol",
        items: [
            {
                nameET: "Singhai 5% 33cl",
                price: 5
            },
            {
                nameET: "Chang 5% 32cl",
                price: 6
            },
            {
                nameET: "Heineken 5% 33cl",
                price: 5
            },
            {
                nameET: "Staropramen 5% 50cl",
                price: 5
            },
            {
                nameET: "A.Le Coq Premium 4.7% 50cl",
                price: 5
            },
            {
                nameET: "Punane vein 16cl",
                nameEN: "Red wine 16cl",
                price: 5
            },
            {
                nameET: "Punane vein 75cl",
                nameEN: "Red wine 75cl",
                price: 22
            },
            {
                nameET: "Valge vein 16cl",
                nameEN: "White wine 16cl",
                price: 5
            },
            {
                nameET: "Valge vein 75cl",
                nameEN: "White wine 75cl",
                price: 22
            }
        ]
    }
]