export const LogoBig = "../public/logo512png"
export const LogoSmall = "../public/logo192png"

export const Email = "thaistopfood@gmail.com"
export const Slogan = "Authentic Thai cuisine";
export const PageName = "Thai Stop";
export const Phone = "+37256648575";
export const PhoneDisplay = "+372 5664 8575";
export const Address = "Koidu 108, Tallinn";
export const AddressLink = "https://maps.app.goo.gl/yNELfXLDgY2gYN8w7";

export const OpenDaysFirstRow = "T-L"
export const OpenTimesFirstRow = "12:00 - 21:00";
export const OpenDaysSecondRow = "P";
export const OpenTimesSecondRow = "12:00 - 20:00";
