import { FaInstagramSquare, FaFacebookSquare } from 'react-icons/fa';
import {FacebookLink, InstagramLink} from "../../config/footer";
import {Email} from "../../config/general";


export const Footer = () => {
    return <div className={"footer-container"}>
        <div className={"social-icons"}>
            <a href={InstagramLink} className={"instagram"}>
                <FaInstagramSquare style={{
                    color: "#FFF"
                }}/>
            </a>
            <a href={FacebookLink}>
                <FaFacebookSquare style={{
                    color: "#FFF"
                }}/>
            </a>
        </div>
        <div className={"email-container"}>

            <a className="email-link" href={`mailto:${Email}`}>{Email}</a>
        </div>
    </div>
}