import {Header} from "./components/header";
import React from "react";
import { Footer } from "./components/footer";
import { Content } from "./components/content";

export const MainLayout = (props: any) => {
    return <div className={"main-layout-container"}>
        <div className={"main-container"}>
            <Header/>

            <div className={"main-inner-container"}>
                <Content>
                    {props.children}
                </Content>
                <Footer/>
            </div>
        </div>
        <div className={"overlay"}></div>
    </div>
}