import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {ErrorPage} from "./pages/system/ErrorPage";
import {Menu} from "./pages/menu/menu";
import {Home} from "./pages/homepage/home";
import {MainLayout} from "./layout/main";
import './App.css';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/menu",
        element: <Menu />
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const headElement = document.querySelector("head")

if(headElement) {
    const linkEl = document.createElement("link")
    linkEl.rel = "preconnect"
    linkEl.href = "https://fonts.googleapis.com"

    headElement.appendChild(linkEl)

    const linkEl2 = document.createElement("link")
    linkEl2.rel = "preconnect"
    linkEl2.href = "https://fonts.gstatic.com"
    linkEl2.crossOrigin = "anonymous"

    headElement.appendChild(linkEl2)

    const linkEl3 = document.createElement("link")
    linkEl3.rel = "stylesheet"
    linkEl3.href = "https://fonts.googleapis.com/css2?family=Macondo&display=swap"

    headElement.appendChild(linkEl3)

}

root.render(
  <React.StrictMode>
    <MainLayout>
        <RouterProvider router={router} />
    </MainLayout>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
