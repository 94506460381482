import {menuItems} from "../../config/menu";
import { FaPepperHot } from 'react-icons/fa';

const chunk = (arr: any[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );

export const Menu = () => {
    const products = menuItems

    const renderMenu = () => {
        return products.map(item => {
            const productsInOneColumn = Math.ceil(item.items.length / 2)
            const chunks = chunk(item.items, productsInOneColumn)

            return <div key={item.category}>
                <h2 className={"product-category"}>{item.category}</h2>

                <div className={"products-container"}>
                    <div className={"products-left-container product-sides"}>
                        {chunks[0].map(item => <MenuItem key={item.nr} nameEn={item.nameEN} nameEt={item.nameET} nr={item.nr} price={item.price} peppers={item.peppers} />)}
                    </div>
                    <div className={"products-right-container product-sides"}>
                        {chunks[1].map(item => <MenuItem key={item.nr} nameEn={item.nameEN} nameEt={item.nameET} nr={item.nr} price={item.price} peppers={item.peppers} />)}
                    </div>
                </div>
            </div>
        })
    }

    return <div className={"menu-page-container"}>
        <div className={"menu-container"}>{renderMenu()}</div>
        <div className={"menu-disclaimer"}>
            <div>Peaaegu kõiki toite menüüs saab valmistada kasutades liha asemel tofut. Anna oma soovist meile teada!</div>
            <div>Almost all dishes on the menu can be prepared using tofu instead of meat. Let us know your wish!</div>
        </div>
        <div>
            <p>NB! Toitude järel menüüs on tsillikauna pilt , mis tähistab vürtsikuse taset. Soovid ekstravürtsikat rooga või hoopis mahedamat?
            Anna meile oma eelistustest teada ja valmistame toidu just sinu maitsele vastavalt!</p>

            <p>NB! After the dishes in the menu, there is a picture of a chili pod , wich indicates the level of spiciness. Do you want extra-spicy dish or something milder? Let us know your preferences and we will prepare the food according to your taste!</p>
        </div>
        <div>
            <div>Toitudele on võimalus lisada ekstrakogus kana/krevette/riisi 3.-</div>
            <div>It is possible to add an extra amount of chicken/shrimps/rice to the meals 3.-</div>
        </div>
    </div>
}

type Props = {
    nr: number,
    nameEt: string,
    nameEn: string,
    price: number,
    peppers: number
}
const MenuItem = ({nr, nameEt, nameEn, price, peppers}: Props) => {
    const pepperIcons = () => {
        console.log("peppers", peppers)
        const pepperArr = new Array(peppers)
        if(Object.seal) {
            pepperArr.fill(undefined);
            Object.seal(pepperArr);
        }

        console.log("pepperArr", pepperArr)

        return <div className={"pepper-container"}>
            {pepperArr.map(pepper => <FaPepperHot key={pepper} style={{
                color: "#d42031"
            }} />)}
        </div>
    }

    console.log("Menu Item peppers", peppers)
    return <div className={"product-row"}>
        {nr ? <span className={"product-nr"}>{nr}.</span> : <></>}
        <div className={"product-inner-row"}>
            <div>
                <div className={"product-name"}>{nameEt}</div>
                <div className={"product-name"}>
                    {nameEn}
                </div>
            </div>
            <div className={"price-and-peppers"}>
                {peppers > 0 ? pepperIcons() : ""}
                <span className={"product-price"}>{price.toFixed(2)}€</span>
            </div>
        </div>
    </div>
}